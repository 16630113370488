var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-container",
    { staticClass: "container" },
    [
      _c("el-header", { staticClass: "header" }, [_c("header-top")], 1),
      _c(
        "el-main",
        { staticClass: "main" },
        [
          _c(
            "el-row",
            { attrs: { gutter: 30 } },
            [
              _c(
                "el-col",
                {
                  staticClass: "con-left",
                  attrs: { xs: 15, sm: 15, md: 15, lg: 14, xl: 16 },
                },
                [
                  _c(
                    "el-tabs",
                    {
                      model: {
                        value: _vm.activeName,
                        callback: function ($$v) {
                          _vm.activeName = $$v
                        },
                        expression: "activeName",
                      },
                    },
                    [
                      _vm.getCompileState(this.result)
                        ? _c(
                            "el-tab-pane",
                            { attrs: { label: "编译信息", name: "first" } },
                            [
                              _c("div", { staticClass: "leftitem" }, [
                                _c("div", { staticClass: "contents" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "contentshow",
                                      staticStyle: { overflow: "scroll" },
                                    },
                                    [
                                      _c("pre", {
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.CompileInformation
                                          ),
                                        },
                                      }),
                                    ]
                                  ),
                                ]),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "el-tab-pane",
                        { attrs: { label: "源代码", name: "second" } },
                        [
                          _c("div", { staticClass: "leftitem" }, [
                            _c(
                              "div",
                              { staticClass: "contents" },
                              [
                                _c(
                                  "el-row",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "align-items": "center",
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "linevertical" }),
                                    _c("span", [_vm._v("源代码")]),
                                    _c(
                                      "el-button",
                                      {
                                        directives: [
                                          {
                                            name: "clipboard",
                                            rawName: "v-clipboard:copy",
                                            value: _vm.invitationCode,
                                            expression: "invitationCode",
                                            arg: "copy",
                                          },
                                          {
                                            name: "clipboard",
                                            rawName: "v-clipboard:success",
                                            value: _vm.copy,
                                            expression: "copy",
                                            arg: "success",
                                          },
                                          {
                                            name: "clipboard",
                                            rawName: "v-clipboard:error",
                                            value: _vm.onError,
                                            expression: "onError",
                                            arg: "error",
                                          },
                                        ],
                                        staticStyle: { "margin-left": "15px" },
                                        attrs: {
                                          type: "warning",
                                          size: "mini",
                                          plain: "",
                                        },
                                      },
                                      [_vm._v("复制")]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "highlight",
                                        rawName: "v-highlight",
                                      },
                                    ],
                                    staticClass: "contentshow",
                                    staticStyle: { overflow: "scroll" },
                                  },
                                  [
                                    _c("pre", [
                                      _vm._v("                    "),
                                      _c("code", {
                                        domProps: {
                                          textContent: _vm._s(_vm.showSourse),
                                        },
                                      }),
                                      _vm._v("\n                  "),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]
                      ),
                      _vm.getRunState(this.result)
                        ? _c(
                            "el-tab-pane",
                            { attrs: { label: "运行信息", name: "third" } },
                            [
                              _c("div", { staticClass: "leftitem" }, [
                                _c("div", { staticClass: "contents" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "contentshow",
                                      staticStyle: { overflow: "scroll" },
                                    },
                                    [
                                      _c("pre", {
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.runInformation
                                          ),
                                        },
                                      }),
                                    ]
                                  ),
                                ]),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm.getTopicState(this.result)
                        ? _c(
                            "el-tab-pane",
                            { attrs: { label: "判题结果", name: "fourthly" } },
                            [
                              _c("div", { staticClass: "leftitem" }, [
                                _c("div", { staticClass: "contents" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "contentshow",
                                      staticStyle: { overflow: "scroll" },
                                    },
                                    [
                                      _c("pre", {
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.runInformation
                                          ),
                                        },
                                      }),
                                    ]
                                  ),
                                ]),
                              ]),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                {
                  staticClass: "con-right",
                  attrs: { xs: 9, sm: 9, md: 9, lg: 10, xl: 8 },
                },
                [
                  _c(
                    "el-row",
                    { staticClass: "rightcontent" },
                    [
                      _c("el-col", { staticClass: "title" }, [
                        _c("span", {
                          staticStyle: { "margin-right": "20px" },
                          domProps: { textContent: _vm._s(_vm.stuId) },
                        }),
                        _c("span", {
                          domProps: { textContent: _vm._s(_vm.stuName) },
                        }),
                      ]),
                      _c("el-col", { staticClass: "secondtitle colcls" }, [
                        _vm._v(" 所属题目： "),
                        _c("span", {
                          staticStyle: { "margin-left": "15px" },
                          domProps: { textContent: _vm._s(_vm.problemName) },
                        }),
                      ]),
                      _c("el-col", { staticClass: "secondtitle colcls" }, [
                        _vm._v(" 提交结果： "),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "margin-left": "15px",
                              "font-weight": "bold",
                            },
                            style:
                              "color" + ":" + _vm.getStateColor(this.result),
                            domProps: {
                              textContent: _vm._s(_vm.getSubType(this.result)),
                            },
                          },
                          [_vm._v("正确")]
                        ),
                      ]),
                      _c("el-col", { staticClass: "secondtitle showmessage" }, [
                        _vm._v(" 提交详情： "),
                        _c("div", { staticStyle: { "margin-left": "15px" } }, [
                          _c("i", { staticClass: "el-icon-timer" }),
                          _c("span", {
                            staticStyle: { "margin-left": "5px" },
                            domProps: { textContent: _vm._s(_vm.time) },
                          }),
                          _vm._v("ms "),
                        ]),
                        _c("div", { staticStyle: { "margin-left": "15px" } }, [
                          _c("i", { staticClass: "el-icon-coin" }),
                          _c("span", {
                            staticStyle: { "margin-left": "5px" },
                            domProps: { textContent: _vm._s(_vm.memory) },
                          }),
                          _vm._v("KB "),
                        ]),
                        _c("div", { staticStyle: { "margin-left": "8px" } }, [
                          _c("i", { staticClass: "el-icon-document" }),
                          _c("span", {
                            staticStyle: { "margin-left": "5px" },
                            domProps: { textContent: _vm._s(_vm.code_length) },
                          }),
                          _vm._v(" B "),
                          _c("span", {
                            staticStyle: { "margin-left": "5px" },
                            domProps: {
                              textContent: _vm._s(
                                _vm.getLanText(this.language)
                              ),
                            },
                          }),
                        ]),
                      ]),
                      _c("el-col", { staticClass: "secondtitle colcls" }, [
                        _vm._v(" 提交时间： "),
                        _c("span", {
                          staticStyle: { "margin-left": "15px" },
                          domProps: { textContent: _vm._s(_vm.subTime) },
                        }),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }